<template src="./SimpleCV2.html"/>

<script>
import "jspdf/dist/polyfills.es.js";
import { putFile } from "@/services/files";
import html2pdf from "html2pdf.js";
import jobsService from "@/services/Jobs";
import moment from "moment";
export default {
  name: "SimpleCV2",
  data() {
    /** FORM DATA EXAMPlE
     *
     * {
        firstStage: {
          infoFile: {},
          personalInformation: {
            name: "",
            firstSurname: "example",
            secondSurname: "example",
            cellPhone: "77777777777",
            mail: "examplex772@gmail.com",
            pageWebPersonal: "",
            residenceDepartment: "",
            cityResidence: "",
          },
          socialNetworks: [],
          education: [],
          workingInformation: {
            workArea: "",
            specialization: "",
            aboutMe: "",
          },
          skills: [],
          languages: [],
          workExperience: [],
          references: [],
        },
        secondStage: {
          additionalPersonalData: {
            basicData: {
              typeDocument: "cc",
              documentNumber: 77777777777,
              sex: "M",
              nationality: {
                nationality: "COL",
                country: "Colombia",
              },
            },
            militaryCard: {
              class: "PC",
              number: 77777777777,
              DM: 85112939666,
            },
            dateAndPlaceOfBirth: {
              date: new Date(2000, 1, 3),
              country: "Colombia",
              department: "Antioquia",
              municipality: "Medellin",
            },
            location: {
              correspondenceAddress: "Carrera 64 A #88- 66 - Apto 604 - Suramericana 8",
              country: "Colombia",
              department: "Antioquia",
              municipality: "Medellin",
            },
          },
          additionalEducation: {
            basicAndHalfEducation: {
              basicEducation: 11,
              obtainedTitle: "Este es el titulo",
              degreeDate: new Date(2000, 1, 3),
            },
            higherEducation: [
              {
                academicModality: "TC",
                numberOfSemestersPassed: "10",
                graduate: true,
                titleName: "Ingeniero Electronico0",
                termination: new Date(2015, 5, 3),
                professionalCardNumber: 111111,
              },
              {
                academicModality: "ES",
                numberOfSemestersPassed: "10",
                graduate: false,
                titleName: "Ingeniero Electronico1",
                termination: new Date(2016, 6, 3),
                professionalCardNumber: 222222,
              },
              {
                academicModality: "TL",
                numberOfSemestersPassed: "10",
                graduate: true,
                titleName: "Ingeniero Electronico2",
                termination: new Date(2017, 7, 3),
                professionalCardNumber: 333333,
              },
              {
                academicModality: "MG",
                numberOfSemestersPassed: "10",
                graduate: false,
                titleName: "Ingeniero Electronico3",
                termination: new Date(2018, 8, 3),
                professionalCardNumber: 44444,
              },
              {
                academicModality: "TE",
                numberOfSemestersPassed: "10",
                graduate: true,
                titleName: "Ingeniero Electronico4",
                termination: new Date(2019, 9, 3),
                professionalCardNumber: 555555,
              },
            ],
            idiom: [
              {
                name: "example",
                speech: "R",
                writing: "B",
                reading: "MB",
              }
            ]
          },
          additionalWorkingInformation: {
            currentEmployemtOrCurrentConstract: {
              business: "Indie Level Studio S.A.S",
              publicOrPrivate: "PB",
              country: "Colombia",
              department: "Antioquia",
              municipality: "Medellin",
              emailBusiness: "example.example@indielevelstudio.com",
              phoneNumberBusiness: "(4) 4440731",
              dateInit: new Date(2010, 1, 3),
              dateEnd: new Date(2010, 1, 3),
              position: "Director Creativo",
              dependence: "Director Creativo",
              address: "Cra 48C #10 Sur - 74",
            },
            previusEmployemntOrContract: [
              {
                business: "Colegio Montessori de Medellin",
                publicOrPrivate: "PB",
                country: "Colombia",
                department: "Antioquia",
                municipality: "Medellin",
                emailBusiness: "example742.example@montessori.edu.co",
                phoneNumberBusiness: "(4) 7777777",
                dateInit: new Date(2020, 1, 3),
                dateEnd: new Date(2022, 1, 3),
                position: "Docente Semillero de Videojuegos",
                dependence: "Docente Semillero de Videojuegos",
                address: "Calle 20 A S 20-20",
              },
              {
                business: "Colegio Montessori de Medellin",
                publicOrPrivate: "PR",
                country: "Colombia",
                department: "Antioquia",
                municipality: "Medellin",
                emailBusiness: "example742.example@montessori.edu.co",
                phoneNumberBusiness: "(4) 7777777",
                dateInit: new Date(2010, 1, 3),
                dateEnd: new Date(2011, 1, 3),
                position: "Docente Semillero de Videojuegos",
                dependence: "Docente Semillero de Videojuegos",
                address: "Calle 20 A S 20-55",
              },
              {
                business: "Colegio Montessori de Medellin",
                publicOrPrivate: "PB",
                country: "Colombia",
                department: "Antioquia",
                municipality: "Medellin",
                emailBusiness: "example742.example@montessori.edu.co",
                phoneNumberBusiness: "(4) 7777777",
                dateInit: new Date(2000, 1, 3),
                dateEnd: new Date(2000, 1, 3),
                position: "Docente Semillero de Videojuegos",
                dependence: "Docente Semillero de Videojuegos",
                address: "Calle 20 A S 20-20",
              },
            ],
          },
          totalExperienceTime: {
            publicServer: { years: 10, months: 10 },
            privateSectorEmployee: { years: 10, months: 10 },
            independentWorker: { years: 10, months: 10 },
            totalTimeExperience: { years: 10, months: 10 },
          },
          signatureOfThePublicServantOrContractor: {
            iStateUnderTheGravityOfTheOauthThatYes: true,
            date: "Medellin, 16 de Febrero 2021",
          },
        },
      }
     *
     */
    const form = this.$store.getters.getCv?.form;
    return {
      fingerprint: "",
      form: form,
    }
  },
  async mounted() {
    const form = this.$store.getters.getCv.form;
    if (form) {
      this.fingerprint = await this.toDataUrl(this.$store.getters.getCv.fingerprint);
      this.imageCertificateToPdfFirebase();
    }
  },
  methods: {
    async toDataUrl (url) {
      //Convert to base64
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest();
        //xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
        xhr.onload = function () {
          var reader = new FileReader();
          reader.onloadend = function () {
            resolve(reader.result);
          };
          reader.readAsDataURL(xhr.response);
        };
        xhr.onerror = () => {
          reject({
            status: this.status,
            statusText: xhr.statusText,
          });
        };
        xhr.open("GET", url);
        xhr.responseType = "blob";
        xhr.send();
      });
    },
    async createCanvas() {
      const content = document.getElementById('cv-all');
      let nameFile = "";
      const name = this.$store.getters.getCv.name;
      if (name) {
        nameFile = name
      } else {
        nameFile = `hv-${Math.floor(Math.random() * (9999999 - 1000) + 1000)}.pdf`;
      }
      const options = {
        filename: nameFile,
        margin: [1, 1],
        image: { type: 'png', quality: 0.95 },
        html2canvas: {
          dpi: 500,
          letterRendering: true,
          scale: 2,
          width: 900,
        },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' }
      }
      return { options: options, content: content }
    },
    async imageCertificateToPdfFirebase() {
      try {
        let { options, content } = await this.createCanvas()
        let worker = await html2pdf().set(options).from(content).toPdf().output('blob').then((data) => {
          return data
        });
        this.$store.dispatch('fetchLoading', true);
        const url = await putFile(
          worker,
          "CV-PDF",
          `${options.filename}`
        );
        const dataCv = this.$store.getters.getCv;
        const data =
        {
          name: options.filename,
          url: url,
          curriculumTemplate: dataCv.curriculumTemplate,
          fingerprint: this.fingerprint,
          form: dataCv.form
        }
        if (this.$route.query.curriculumId) {
          this.$store.dispatch('fetchLoading', true);
          jobsService.updateCurriculum({
            id: this.$route.query.curriculumId,
            data: data
          });
        } else {
          this.$store.dispatch('fetchLoading', true);
          jobsService.createdCurriculum(data);
        }
        window.open(url, '_blank');
        this.$store.dispatch('fetchLoading', false);
        this.$router.push("/empleo?enableCV=true");
      } catch (error) {
        console.error(error);
      }
    },
    dateFormat(date) {
      return moment(date).format('YYYY-MM-DD');
    }
  },
}
</script>

<style lang="scss" scoped src="./SimpleCV2.scss">

</style>
